<template>
  <div class="d-flex ga-1 flex-wrap my-1">
    <div
      v-for="(group, index) in groupedItems"
      :key="index"
      class="d-flex ga-1 training-group"
    >
      <template v-for="item in group" :key="item.id">
        <v-tooltip location="top">
          <div>
            {{ item.code }} {{ item.name }} {{ item.unitIndex }}/{{
              item.totalUnits
            }}
          </div>
          <div v-if="item.startAt && item.endAt">
            {{ formatUnitDuration(item) }}
          </div>

          <template #activator="{ props: tooltipProps }">
            <div
              v-bind="tooltipProps"
              class="training-unit"
              :class="{
                'training-unit--planned-today': isPlannedToday(item),
                'training-unit--planned': isPlanned(item),
                'training-unit--finished': item.isFinished,
              }"
            />
          </template>
        </v-tooltip>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup generic="I">
import { get } from 'lodash';
import type { TableCellProp } from '@/types/table';
import type { TrainingUnitDto } from '@/api';
import { startOfDay, endOfDay, parseISO, isSameDay } from 'date-fns';
import { isSameOrAfterDay, isSameOrBeforeDay } from '@/utils/datetime';

const props = defineProps<TableCellProp<I>>();
const items = computed(
  () => get(props.rowItem, props.colKey) as TrainingUnitDto[],
);
const groupedItems = computed(() => {
  const result: TrainingUnitDto[][] = [[]];
  let groupIndex = 0;

  for (const item of items.value) {
    if (result[groupIndex].length === 0) {
      result[groupIndex].push(item);
      continue;
    }

    if (result[groupIndex][result[groupIndex].length - 1].code === item.code) {
      result[groupIndex].push(item);
      continue;
    }

    if (result[groupIndex][result[groupIndex].length - 1].code !== item.code) {
      groupIndex++;
      result[groupIndex] = [];
      result[groupIndex].push(item);
    }
  }

  return result;
});

const { formatDateTime, formatDate, formatTime } = useDateUtils();

const isPlannedToday = (item: TrainingUnitDto) => {
  return (
    item.startAt &&
    isSameOrAfterDay(item.startAt, startOfDay(new Date())) &&
    item.endAt &&
    isSameOrBeforeDay(item.endAt, endOfDay(new Date())) &&
    !item.isFinished
  );
};

const isPlanned = (item: TrainingUnitDto) => {
  return item.startAt && item.endAt && !item.isFinished;
};

const formatUnitDuration = (item: TrainingUnitDto): string => {
  if (item.startAt && item.endAt) {
    const startAt = parseISO(item.startAt);
    const endAt = parseISO(item.endAt);

    if (isSameDay(endAt, startAt)) {
      return `${formatDate(startAt)} ${formatTime(startAt)}-${formatTime(endAt)}`;
    }

    return `${formatDateTime(startAt)} - ${formatDateTime(endAt)}`;
  }

  return '';
};
</script>

<style lang="scss" scoped>
.training-group:hover {
  .training-unit {
    border-color: rgb(var(--v-training-unit--hover)) !important;
  }
}

.training-unit {
  width: 20px;
  height: 20px;
  background-color: rgb(var(--v-training-unit));
  border: 1px solid rgb(var(--v-training-unit));
  cursor: pointer;

  &:hover {
    background-color: rgb(var(--v-training-unit--hover)) !important;
  }

  &.training-unit--planned {
    background-color: rgb(var(--v-training-unit--planned));
    border-color: rgb(var(--v-training-unit--planned));
  }
  &.training-unit--planned-today {
    background-color: rgb(var(--v-training-unit--planned-today));
    border-color: rgb(var(--v-training-unit--planned-today));
  }
  &.training-unit--finished {
    background-color: rgb(var(--v-training-unit--finished));
    border-color: rgb(var(--v-training-unit--finished));
  }
}
</style>
